import React from "react";
import { Dashboard } from "react-ikonate";
import { IconBSMAdmin } from "@wwg/icons";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Card from "shared/Card";
import { Auth } from "shared/@auth/Auth";
import Page, { Content, Nav, NavGroup, NavItem } from "shared/Page";
import StockList from "./StockList/StockList";
import { StockData } from "./StockList/hooks/useStockData";
import { MediaPlayerContainer } from "./StockList/hooks/useMediaPlayer";
import ImageUpload from "./ImageUpload";
import { ImageUploader } from "./ImageUpload/useImageUploader";
import StockEdit from "./StockEdit/StockEdit";

export function BSM() {
  const { path } = useRouteMatch();
  const { hasPermission } = Auth.useContainer();

  return (
    <MediaPlayerContainer.Provider>
      <StockData.Provider>
        <Page>
          <Nav>
            <NavGroup title="Marketing">
              {hasPermission("bsm.*") && (
                <NavItem to={`${path}/stock`} icon={<IconBSMAdmin />}>
                  Stock
                </NavItem>
              )}

              {hasPermission("bsm.*") && (
                <NavItem to={`${path}/image-upload`} icon={<Dashboard />}>
                  Image Upload
                </NavItem>
              )}
            </NavGroup>
          </Nav>
          <Content>
            <Switch>
              {hasPermission("bsm.*") && (
                <Route exact path={`${path}/stock`}>
                  <Card>
                    <header
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1em",
                      }}
                    >
                      <IconBSMAdmin size={32} />
                      <h3 style={{ paddingLeft: "0.5em" }}>BSM</h3>
                    </header>
                    <StockList />
                  </Card>
                </Route>
              )}

              {hasPermission("bsm.*") && (
                <Route path={`${path}/stock/:id/edit`}>
                  <StockEdit />
                </Route>
              )}

              {hasPermission("bsm.*") && (
                <Route path={`${path}/image-upload`}>
                  <ImageUploader.Provider>
                    <ImageUpload />
                  </ImageUploader.Provider>
                </Route>
              )}

              <Route path="*">
                <Redirect to={`${path}/stock`} />
              </Route>
            </Switch>
          </Content>
        </Page>
      </StockData.Provider>
    </MediaPlayerContainer.Provider>
  );
}
