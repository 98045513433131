import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { SORT_METHOD } from "BSM/StockList/enums";
import styles from "./index.module.css";
import classNames from "classnames";

/**
 * Dropdown menu that gives options to sort the stock list
 */
export function SortDropdown({ sortBy, setSortBy }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const onOptionClicked = (value) => () => {
    setSortBy(value);
    setIsOpen(false);
  };

  const toggle = () => setIsOpen(!isOpen);

  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div ref={ref}>
      <div className={styles.dropDownContainer}>
        <div
          data-testid="dropdown-button"
          className={styles.dropDownHeader}
          onClick={toggle}
        >
          {sortBy}
        </div>
        {isOpen && (
          <div className={styles.dropDownDummyPosition}>
            <ul className={styles.dropDownList}>
              <li
                data-testid="dropdown-dateDesc"
                onClick={onOptionClicked(SORT_METHOD.dateDesc)}
                className={classNames(
                  styles.dropDownItem,
                  styles.dropDownDashed
                )}
              >
                {SORT_METHOD.dateDesc}
              </li>
              <li
                data-testid="dropdown-dateAsc"
                onClick={onOptionClicked(SORT_METHOD.dateAsc)}
                className={classNames(
                  styles.dropDownItem,
                  styles.dropDownDashed
                )}
              >
                {SORT_METHOD.dateAsc}
              </li>
              <li
                data-testid="dropdown-titleAsc"
                onClick={onOptionClicked(SORT_METHOD.titleAsc)}
                className={classNames(
                  styles.dropDownItem,
                  styles.dropDownDashed
                )}
              >
                {SORT_METHOD.titleAsc}
              </li>
              <li
                data-testid="dropdown-titleDesc"
                onClick={onOptionClicked(SORT_METHOD.titleDesc)}
                className={styles.dropDownItem}
              >
                {SORT_METHOD.titleDesc}
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

SortDropdown.propTypes = {
  /**
   * String that holds the current sorting option
   */
  sortBy: PropTypes.string,

  /**
   * Function that updates the sorting option
   */
  setSortBy: PropTypes.func,
};
