import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./index.module.css";
import { Button, ButtonPurpose } from "shared/Button/Button";
import SimplifiedModal from "../SimplifiedModal";
import Notification from "shared/Notification";

/**
 * This is a modal for drag and drop or selected image-file appending to a local state
 *
 * @example
 *  <ChangeImageModal
 *   isOpen={isModalOpen}
 *   setModalOpen={setModalOpen}
 *   onChooseImage={() => onChangeImage(img)}
 *  />
 */

export function ChangeImageModal({ onChooseImage, isOpen, setModalOpen }) {
  const imagePreviewRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  useEffect(() => {
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setPreview(url);
      let img = new Image();
      img.onload = function () {
        setError1(this.naturalWidth + this.naturalHeight < 1200 ? true : false);
        setError2(this.naturalWidth !== this.naturalHeight ? true : false);
      };
      img.src = url;
      return () => URL.revokeObjectURL(url);
    } else {
      setPreview(null);
    }
  }, [selectedFile]);

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    setSelectedFile(files[0]);
  };

  function onSelectFile(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null);
      return;
    }
    setSelectedFile(e.target.files[0]);
  }

  function onClick() {
    imagePreviewRef.current.click();
  }

  return (
    <>
      {isOpen && (
        <div className={styles.warnings}>
          {error2 && (
            <Notification
              type={"danger"}
              title={"Warning!"}
              message={"BSM images must be square"}
            />
          )}
          {error1 && (
            <Notification
              type={"danger"}
              title={"Warning!"}
              message={"BSM images must be at least 600px X 600px."}
            />
          )}
        </div>
      )}
      <SimplifiedModal isOpen={isOpen}>
        <div className={classNames(styles.container, styles.row)}>
          <div
            onClick={onClick}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={(e) => {
              fileDrop(e);
            }}
            className={styles.dragndrop}
          >
            {!preview && <span>Select a file or drag one here</span>}
            {preview && (
              <img
                src={preview}
                className={styles.preview}
                alt={preview ?? "alt"}
              />
            )}
          </div>
          <div className={classNames(styles.buttonSection)}>
            {preview && (
              <Button
                purpose={ButtonPurpose.save}
                onClick={() => {
                  onChooseImage(selectedFile);
                }}
              >
                Use This Photo
              </Button>
            )}

            <Button onClick={onClick}>Select Image</Button>
            <Button
              purpose={ButtonPurpose.delete}
              onClick={() => {
                if (typeof setModalOpen === "function") setModalOpen(false);
              }}
            >
              Close
            </Button>
          </div>
          <input
            type="file"
            id="imagePreview"
            ref={imagePreviewRef}
            className={styles.file}
            onChange={onSelectFile}
          />
        </div>
      </SimplifiedModal>
    </>
  );
}

ChangeImageModal.propTypes = {
  /**
   * @param onChangeImage - function to control the storing and modification of an image file
   */
  onChangeImage: PropTypes.func,
  /**
   * @param isOpen - the state of the modal whether it is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * @param setModalOpen - controls the whether the modal is open or not
   */
  setModalOpen: PropTypes.func,
};
