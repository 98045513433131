import styles from "./index.module.css";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * For those times when you have lots of "label and value" type info to display.
 *
 * Displays labels and values in a single readable column.
 *
 * @example
 * <DataList
 *   data={{
 *     "Label": "value",
 *     "Label2": <div>A custom component</div>,
 *     "Label3": 5
 *   }}
 * />
 * // or if you import { DataRow } you can do:
 * <DataList>
 *   <DataRow label="Label">
 *     <div>Any JSX you want</div>
 *   </DataRow>
 * </DataList>
 *
 */
export default function DataList({ data, children }) {
  const rows = [];

  if (data) {
    rows.push(
      ...Object.keys(data).map((label) => (
        <DataRow label={label}>{data[label]}</DataRow>
      ))
    );
  }

  if (children) {
    if (Array.isArray(children)) {
      rows.push(...children);
    } else {
      rows.push(children);
    }
  }

  return <div className={styles.container}>{rows}</div>;
}

export function DataRow({ label, children }) {
  return (
    <div className={styles.row}>
      <div className={classNames(styles.cell, styles.rowLabel)}>{label}</div>
      <div className={classNames(styles.cell, styles.rowContent)}>
        {children}
      </div>
    </div>
  );
}

/*
 * PropTypes.node: Anything that can be rendered: numbers, strings, elements or an array
 * (or fragment) containing these types.
 */

DataList.propTypes = {
  data: PropTypes.object,
  children: PropTypes.node,
};

DataRow.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
};
