import styles from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

/**
 * Displays a green checkmark
 */
export default function GreenCheckmark() {
  return (
    <FontAwesomeIcon icon={faCheckCircle} className={styles.check} size="lg" />
  );
}
