import PropTypes from "prop-types";
import DataList from "shared/DataList";
import { DataRow } from "shared/DataList";
import styles from "./index.module.css";

/**
 * This component handles displaying a stock item's sku, owner, and occupation in the CategoryCheckboxForm
 * 
 * @example
 *  <SettingDetails
 *   sku={data.sku}
 *   owner={data.creator}
 *   occupation={data.subtitle}
 *  />
 */

export function SettingDetails({ sku = "", owner = "", occupation = "", mediaId = "" }) {
  return (
    <section className={styles.container}>
      <h2 className={styles.header}>Settings</h2>
      <h4 /* className={styles.subHeader} */>Details</h4>
      <div className={styles.row}>
        <DataList>
          <DataRow
            label={"SKU:"}
            className={{ dataRow: styles.row }}
            children={sku}
          />
          <DataRow
            label={"Creator/Speaker/Author:"}
            className={{ dataRow: styles.row }}
            children={owner}
          />
          <DataRow
            label={"Occupation/BookCategory:"}
            className={{ dataRow: styles.row }}
            children={occupation}
          />
          <DataRow
            label={"Media ID:"}
            className={{ dataRow: styles.row }}
            children={mediaId}
          />
        </DataList>
      </div>
    </section>
  );
}

SettingDetails.propTypes = {
  /**
   * @param sku - a stock item's sku
   */
  sku: PropTypes.string,
  /**
   * @param owner - a stock item's creator/speaker/autor/owner
   */
  owner: PropTypes.string,
  /**
   * @param occupation - a stock item's occupation/bookcategory
   */
  occupation: PropTypes.string,

  /**
   * @param mediaId - a media ID to play this stock item
   */
   mediaId: PropTypes.string,
};
