import styles from "./index.module.css";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Displays a turning gear
 */
export default function WaitingGear() {
  return <FontAwesomeIcon icon={faCog} className={styles.gear} size="lg" />;
}
