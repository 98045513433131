import React, { useState, useEffect } from "react";
import { Button } from "@wwg/ui";
import styles from "./MediaModal.module.css";
import { MediaPlayerContainer } from "../../hooks/useMediaPlayer";
import { Modal } from "@wwg/ui";
import PropTypes from "prop-types";

/**
 * A modal that displays and plays the media that the user selected
 */
export function PureMediaModal({
  toggleMediaPlayer,
  showMediaPlayer,
  playingMedia,
}) {
  if (showMediaPlayer === false) {
    return null;
  } else if ([15, 21].includes(playingMedia.toolType)) {
    // File Type is Audio
    return (
      <Modal animation={"Down"} visible={true} onClick={toggleMediaPlayer}>
        <div className={styles.mediaContainer}>
          <div className={styles.mediaModalAudio}>
            <div className={styles.mediaPlayer}>
              <iframe
                src={showMediaPlayer ? playingMedia.playerPath : null}
              ></iframe>
            </div>
            <div className={styles.buttonGroup}>
              <Button className={styles.button} onClick={toggleMediaPlayer}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  } else if ([16, 18].includes(playingMedia.toolType)) {
    // File Type is video
    return (
      <Modal
        animation={"Down"}
        visible={showMediaPlayer}
        onClick={toggleMediaPlayer}
      >
        <div className={styles.mediaContainer}>
          <div className={styles.mediaModalVideo}>
            <div>
              <iframe
                src={showMediaPlayer ? playingMedia.playerPath : null}
              ></iframe>
            </div>
            <div className={styles.buttonGroup}>
              <Button className={styles.button} onClick={toggleMediaPlayer}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
}

PureMediaModal.propTypes = {
  /**
   * Function that toggles the the presence of the media player
   */
  toggleMediaPlayer: PropTypes.func,

  /**
   * Boolean that holds whether or not the media player is showing
   */
  showMediaPlayer: PropTypes.bool,

  /**
   * An object of the media currently being played
   */
  playingMedia: PropTypes.shape({
    toolType: PropTypes.number,
    playerPath: PropTypes.string,
  }),
};

/**
 * A specific media modal for the BSM
 */
export default function MediaModal() {
  const {
    toggleMediaPlayer,
    showMediaPlayer,
    playingMedia,
  } = MediaPlayerContainer.useContainer();

  return PureMediaModal({
    toggleMediaPlayer,
    showMediaPlayer,
    playingMedia,
  });
}
