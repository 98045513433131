import styles from "./index.module.css";

import { Redirect, useParams } from "react-router-dom";
import { useStockEditData } from "./components/@hooks/useStockEditData";

import Card from "shared/Card";
import ControlsHeader from "./components/ControlsHeader";
import ChangeImage from "./components/ChangeImage";
import LoadingPage from "shared/LoadingPage";
import CategoryCheckboxForm from "./components/CategoryCheckboxForm";
import SettingDetails from "./components/SettingDetails";
import TextInputForm from "./components/TextInputForm";
import { StockData } from "BSM/StockList/hooks/useStockData";

/**
 * This is the BSM stock edit page, used to edit the title, creator, subtitle,
 * description, tags, and if it is a CD or DVD the thumbnail image.
 *
 * @example <StockEdit/>
 */

export default function StockEdit() {
  const { id } = useParams();

  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    isChanged,
    content,
    methods,
    updateStockMutation,
    updateStockImageThumbMutation,
    updateStockImageFullMutation
  } = useStockEditData(id);

  const {tags} = StockData.useContainer();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    console.error(error);
    return <Redirect push to={"/bsm/stock"} />;
  }

  if (isSuccess) {
    return (
      <Card className={styles.card}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ControlsHeader
            title={data.title}
            changesMade={isChanged}
            onReset={methods.reset}
            onSave={async () => {
              var imageFile = content.imageFile;
              await updateStockMutation.mutateAsync();
              await updateStockImageThumbMutation.mutateAsync({imageFile: imageFile});
              await updateStockImageFullMutation.mutateAsync({imageFile: imageFile});
            }}
          />
          <section className={styles.splitPane}>
            <span>
              <TextInputForm
                title={content.title}
                subtitle={content.subtitle}
                creator={content.creator}
                description={content.description}
                setTitle={methods.setTitle}
                setCreator={methods.setCreator}
                setSubtitle={methods.setSubtitle}
                setDescription={methods.setDescription}
              />
              <ChangeImage
                stockImage={content ? content.images : ""}
                onChangeImage={methods.setImageFile}
              />
            </span>
            <Card className={styles.card}>
              <SettingDetails
                sku={data.sku}
                owner={data.creator}
                occupation={data.subtitle}
                mediaId={data.mediaId}
              />
              <CategoryCheckboxForm
                stockItemsTags={content.tags ? [...content.tags] : [undefined]}
                setStockItemsTags={methods.setTags}
                setNewTags={methods.setNewTags}
                newTags={content.newTags}
                originalTags={tags.map(a=>a.label).flat()}
              />
            </Card>
          </section>
        </form>
      </Card>
    );
  }
}
