import { useState, useEffect, useMemo, useCallback } from "react";
import { createContainer } from "unstated-next";
import { matchSorter } from "match-sorter";
import { sortBy as _sortBy, uniqBy as _uniqBy } from "lodash-es";
import { useInfiniteQuery, useQuery } from "react-query";
import { Auth } from "shared/@auth/Auth";
import { useLogger } from "shared/@hooks/useLogger";
import { SORT_METHOD } from "../enums";
import { clone } from "lodash-es";

export function useStockData() {
  const { makeAuthedRequest } = Auth.useContainer();
  const { logError } = useLogger({ prefix: "useStockData" });

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [sortBy, setSortBy] = useState(SORT_METHOD.dateDesc);


  const { data:tagsData } = useQuery(
    // Query Key for invalidating data
    ["bsm", "tags"],
      ()=>makeAuthedRequest(`/bsm/bsm/tags`).then(async (res) => {
        if (res.ok) {
          return res.json()
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      })
  );

  const { isLoading, isError, data, error, refetch, hasNextPage, fetchNextPage } = useInfiniteQuery(
    // Query Key for invalidating data
    ["bsm", "all", searchTerm, sortBy, selectedFilters],
    ({ pageParam = 0 }) =>{
      var sortTerm = "title"
      var sortOrder = "asc"
      switch (sortBy) {
        case SORT_METHOD.titleAsc:
          sortTerm = "title"
          sortOrder = "asc"
          break;
        case SORT_METHOD.titleDesc:
          sortTerm = "title"
          sortOrder = "desc"
          break;
        case SORT_METHOD.dateAsc:
          sortTerm = "date"
          sortOrder = "asc"
          break;
        case SORT_METHOD.dateDesc:
          sortTerm = "date"
          sortOrder = "desc"
          break;
        default:
          break;
      }

      let tags = selectedFilters.filter(a=>a.type == "tags").map(a=>a.desiredResult).flat();
      let is3rdParties = selectedFilters.filter(a=>a.type == "is3rdParty").map(a=>a.desiredResult).flat();
      let toolTypes = selectedFilters.filter(a=>a.type == "toolType").map(a=>a.desiredResult).flat();

      var url = `/bsm/bsm/stock`
      + `?page=${pageParam}`
      + `&term=${searchTerm}`
      + `&sortBy=${sortTerm}`
      + `&sortOrder=${sortOrder}`
      + `&tags=${tags.join(",")}`
      + `&is3rdParties=${is3rdParties.join(",")}`
      + `&toolTypes=${toolTypes.join(",")}`

      return makeAuthedRequest(url).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      })},
      {
        getNextPageParam: lastPage => {
          if (lastPage.next !== null) {
            return lastPage.next;
          }
    
          return null;
        }
      }
  );

  // Log to screen when an API error occurs.
  useEffect(() => {
    if (isError) {
      logError(error);
    }
  }, [isError, error]);

  // Destructure data object from API. Return empty defaults in case data is null.
  const { items } = useMemo(() => {
    if (data == null) {
      return {
        items: [],
      };
    } else {
      return {
        items: data.pages.map(page => page.results).flat(),
      };
    }
  }, [data]);

  // Destructure data object from API. Return empty defaults in case data is null.
  const { tags } = useMemo(() => {
    if (tagsData == null) {
      return {
        tags: [],
      };
    } else {
      return {
        tags: tagsData.map(tag=> {return { id: tag, label: tag, desiredResult: tag }}),
      };
    }
  }, [tagsData]);

  let filters = [
    {
      type: "is3rdParty",
      title: null,
      array: [
        {
          id: 1,
          label: "WWG",
          desiredResult: false,
        },
        {
          id: 2,
          label: "3rd Party",
          desiredResult: true,
        },
      ],
    },
    {
      type: "toolType",
      title: "Media",
      array: [
        {
          id: 1,
          label: "Audio",
          desiredResult: [15, 21],
        },
        {
          id: 2,
          label: "Video",
          desiredResult: [16, 18],
        },
        {
          id: 3,
          label: "Books",
          desiredResult: [1],
        },
        {
          id: 4,
          label: "Literature & MISC",
          desiredResult: [12],
        },
      ],
    },
    {
      type: "tags",
      title: "tags",
      array: tags,
    },
  ];

  return {
    // Request state
    isLoading,
    isError,
    error,

    // Request data
    filters,

    // State accessors
    selectedFilters,
    setSelectedFilters,
    sortBy,
    setSortBy,
    searchTerm,
    setSearchTerm,

    tags,
    items,
    hasNextPage, 
    fetchNextPage,

    refreshAll: refetch,
  };
}

export const StockData = createContainer(useStockData);
