import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { Button, ButtonPurpose } from "shared/Button/Button";
import PropTypes from "prop-types";

/**
 * Takes a file as `value` and passes a new image file to `onChange` when picked.
 */
export default function ImagePicker({ values, defaultValue, onChange, label }) {
  const thumbnail = useRef(null);
  const [previews, setPreviews] = useState([]);

  useEffect(() => {
    if (!values) {
      setPreviews([]);
      return;
    }

    Object.values(values).forEach((image) => {
      const url = URL.createObjectURL(image);
      setPreviews((previews) => [...previews, url]);

      return () => URL.revokeObjectURL(url);
    });
  }, [values]);

  let image;

  if (previews) {
    previews.forEach((preview) => {
      image = <img src={preview} className={styles.thumbIcon} />;
    });
  } else if (defaultValue) {
    image = <img src={defaultValue} className={styles.thumbIcon} />;
  } else {
    image = (
      <div className={styles.thumbPlaceholder}>
        <span>No Image</span>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Button
        className={styles.btn}
        purpose={ButtonPurpose.generic}
        onClick={(e) => {
          e.preventDefault();
          thumbnail.current.click();
        }}
      >
        {label}
      </Button>

      <input
        type="file"
        id="thumbnail"
        ref={thumbnail}
        multiple="multiple"
        className={styles.input}
        onChange={(e) => {
          if (typeof onChange === "function") {
            if (!e.target.files || e.target.files.length === 0) {
              onChange(undefined);
              return;
            }
            onChange(e.target.files);
          }
        }}
      />
    </div>
  );
}

ImagePicker.propTypes = {
  /** This is the image that user picked to be displayed */
  values: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(
      PropTypes.shape({
        lastModified: PropTypes.number,
        lastModifiedDate: PropTypes.string,
        name: PropTypes.string,
        size: PropTypes.number,
        type: PropTypes.string,
        webkitRelativePath: PropTypes.string,
      })
    ),
  ]),

  /** The `defaultValue` is the image displayed if `values` is null */
  defaultValue: PropTypes.string,

  /** The function that changes the current image file that was selected */
  onChange: PropTypes.func,

  /** The `label` is text to be shown on the button that triggers the file choose dialog. */
  label: PropTypes.string,
};
