import PropTypes from "prop-types";
import styles from "./index.module.css";
import { Button, ButtonPurpose, ButtonLink } from "shared/Button/Button";

/**
 * Displays a bar with header text on the left and custom controls on the right.
 *
 * @example
 *  <ControlsHeader
 *    title={"title"}
 *    changesMade={isChanged}
 *    onReset={()=>reset()}
 *    onSave={()=>save()}
 *  />
 */

export function ControlsHeader({
  title = "Title",
  changesMade = false,
  onReset,
  onSave,
}) {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title ?? ""}</h2>
      <div className={styles.controls}>
        {changesMade && <span>Save Changes?</span>}
        <ButtonLink purpose={ButtonPurpose.delete} to={"/bsm/stock"}>
          Cancel
        </ButtonLink>
        {changesMade && (
          <>
            <Button onClick={() => onReset()}>Reset</Button>
            <Button onClick={() => onSave()} purpose={ButtonPurpose.save}>
              Save
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

ControlsHeader.propTypes = {
  /**
   * @param title - the title of the stock item currently observed
   */
  title: PropTypes.string,
  /**
   * @param changesMade - a boolean determined by comparison between the local and remote state of the stock item
   */
  changesMade: PropTypes.bool,
  /**
   * @param onReset - a function to execute on clicking the reset button
   */
  onReset: PropTypes.func,
  /**
   * @param onSave - a function to execute on clicking the save button
   */
  onSave: PropTypes.func,
};
