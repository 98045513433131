import styles from "./index.module.css";

/**
 * Displays header from Image Uploader
 */
export default function Header() {
  return (
    <div>
      <div className={styles.title}>Image Uploader</div>
      <div className={styles.info}>
        Images must be square with minimum dimensions of 600 x 600px. Image must
        be named with the Stock's SKU. Example: BK104.png, WW513B.png
      </div>
    </div>
  );
}
