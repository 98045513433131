import React, { useCallback } from "react";
import PropTypes from "prop-types";

import styles from "./EditButton.module.css";
import { IconEdit } from "@wwg/icons";
import { useHistory } from "react-router-dom";

/**
 * Button that directs the user to the edit page for the selected BSM Stock
 */
export const EditButton = React.memo(({ StockItemId, size = 32 }) => {
  let history = useHistory();

  const _onEdit = useCallback((e) => {
    e.preventDefault();
    if (history) {
      history.replace(`/bsm/stock/${StockItemId}/edit`);
    }
  });

  return (
    <button aria-label="edit" className={styles.button} onClick={_onEdit}>
      <IconEdit size={size} />
    </button>
  );
});

EditButton.propTypes = {
  /**
   * Stock item's ID, used to retrieve data on edit page
   */
  StockItemId: PropTypes.number,

  /**
   * Size of the button in pixels square.
   */
  size: PropTypes.number,
};
