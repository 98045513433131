import PropTypes from "prop-types";
import styles from "./index.module.css";
import ReactModal from "react-modal";
import classNames from "classnames";

/**
 * A bare-bones version of the Modal component for defining modals and controlling their state in your components.
 *
 * @example
 * <SimplifiedModal isOpen={isOpen} onClick={()=>{console.alert("this function is passed through ...props")}} >
 * <p> Children Go Here </p>
 * </SimplifiedModal >
 */

export function SimplifiedModal({ isOpen, children, className, ...props }) {
  return (
    <ReactModal
      isOpen={isOpen}
      className={classNames(styles.modal, className ? className.modal : null)}
      overlayClassName={classNames(
        styles.overlay,
        className ? className.overlay : null
      )}
      {...props}
    >
      {children}
    </ReactModal>
  );
}

SimplifiedModal.propTypes = {
  /**
   * @param isOpen - boolean that controls whether the modal is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * @param children - any renderable the needs to be in the modal
   */
  children: PropTypes.node,
  /**
   * @param className -a way to modify the deisgn of the modal
   */
  className: PropTypes.string,
  /**
   * @param props - this is passed through to be able to make any dom element changes/affixations such as event handlers or properties
   */
  props: PropTypes.any,
};
