import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";
import { Auth } from "shared/@auth/Auth";
import { StockData } from "BSM/StockList/hooks/useStockData";
import { useMutation } from "react-query";
import createNotification from "shared/@utils/createNotification";

export function useImageUploader() {
  const [listItems, setListItems] = useState([]);
  const [stockItems, setStockItems] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const { makeAuthedRequest } = Auth.useContainer();
  const { items } = StockData.useContainer();

  useEffect(() => {
    setStockItems(items);
  }, [listItems]);

  const addImage = useMutation(
    ({ stockItem, data, newListItems, index, newWidth, newHeight }) =>
      makeAuthedRequest(
        `bsm/stock/${stockItem.stockId}/image?width=${newWidth}&height=${newHeight}&type=2`,
        {
          headers: {
            "Content-Type": undefined,
          },
          method: "PUT",
          body: data,
        }
      ).then(async (res) => {
        if (res.ok) {
          newListItems[index].uploaded = true;
          setIsWaiting(false);
          const json = await res.json();
        } else {
          setErrMessage("1 or more Image Failed to Upload");
          newListItems[index].error = true;
          //console.warn(res);
          setIsWaiting(false);
          throw new Error(res.message);
        }
        setListItems([...newListItems]);
      }),
    {
      onSuccess: () => {
        createNotification({
          type: "success",
          title: "Uploaded",
          message: "Image has been uploaded.",
          autoDismiss: true,
        });
      },
    }
  );

  const onUpload = () => {
    let newListItems = [...listItems];
    listItems.forEach(async (item, index) => {
      setIsWaiting(true);
      const stockItem = stockItems.find((stock) => stock.sku === item.sku);
      const data = new FormData();
      data.append("file", item.file);

      if (!item.uploaded) {
        setErrMessage("");
        let newWidth = item.width;
        let newHeight = item.height;
        addImage.mutate({
          stockItem,
          data,
          newListItems,
          index,
          newWidth,
          newHeight,
        });
      }
    });
  };

  return {
    listItems,
    setListItems,
    errMessage,
    setErrMessage,
    stockItems,
    onUpload,
    isWaiting,
  };
}

export const ImageUploader = createContainer(useImageUploader);
