import React, { useMemo, useState } from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Crossbox } from "../@forms/Crossbox";
import { ChevronDown, ChevronUp } from "react-ikonate";
import { toTitleCase } from "shared/@utils/formatString";

export function FilterColumn({ ...props }) {
  const { filters, selectedFilters, setSelectedFilters, isHideCollapse } =
    props;
  const [dropdownOpen, setDropdownOpen] = useState(
    Array(filters.length).fill(false)
  );

  let reducedFilters = useMemo(() => {
    return selectedFilters.map((filter) => {
      return filter.filterId;
    });
  }, [selectedFilters]);

  const toggleDropdown = (index) => {
    const updatedDropdownOpen = [...dropdownOpen];
    updatedDropdownOpen[index] = !updatedDropdownOpen[index];
    setDropdownOpen(updatedDropdownOpen);
  };

  return (
    <div className={classNames(styles.filters, styles.col)}>
      {filters &&
        filters.map((filter, index) => {
          return (
            <div
              key={index}
              className={classNames(styles.section, {
                [styles.dropdownOpen]: dropdownOpen[index],
              })}
            >
              <div
                className={styles.dropdownToggle}
                onClick={() => !isHideCollapse && toggleDropdown(index)}
              >
                {filter.title && (
                  <div className={styles.title}>
                    Filter by {toTitleCase(filter.title)}
                  </div>
                )}
                {!isHideCollapse &&
                  (dropdownOpen[index] ? (
                    <ChevronUp width={26} height={26} />
                  ) : (
                    <ChevronDown width={26} height={26} />
                  ))}
              </div>
              {dropdownOpen[index] ||
                (isHideCollapse && (
                  <div className={styles.filters}>
                    {filter.array.map((category, i) => {
                      return (
                        <div className={styles.checkbox} key={i}>
                          <Crossbox
                            label={category.label}
                            checked={reducedFilters.includes(
                              `${filter.type}${category.id}`
                            )}
                            onChange={() => {
                              let obj = {
                                filterId: `${filter.type}${category.id}`,
                                title: filter.title,
                                id: category.id,
                                label: category.label,
                                filter: filter,
                                desiredResult:
                                  category.desiredResult !== undefined
                                    ? category.desiredResult
                                    : undefined,
                                type: filter.type,
                              };

                              if (
                                reducedFilters.includes(
                                  `${filter.type}${category.id}`
                                )
                              ) {
                                setSelectedFilters(
                                  selectedFilters.filter(
                                    (f) =>
                                      f.filterId !==
                                      `${filter.type}${category.id}`
                                  )
                                );
                              } else {
                                setSelectedFilters([...selectedFilters, obj]);
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ))}
            </div>
          );
        })}
    </div>
  );
}

FilterColumn.propTypes = {
  filters: PropTypes.array,
};
