import React from "react";
import styles from "./index.module.css";
import { Button, ButtonPurpose } from "shared/Button/Button";
import PropTypes from "prop-types";

/**
 * Button used to loop through list items and upload the new images. If the image is uploaded the listitems will be updated to have a green checkmark to the left.
 */
export default function UploadImages({ onUpload }) {
  return (
    <Button
      className={styles.upload}
      purpose={ButtonPurpose.generic}
      onClick={onUpload}
    >
      Upload These Images
    </Button>
  );
}

UploadImages.propTypes = {
  /** Function that uploads the new images to the stock items */
  onUpload: PropTypes.func,
};
