import React, { useCallback } from "react";
import PropTypes from "prop-types";

import styles from "./MediaButton.module.css";
import { IconPlay } from "@wwg/icons";

/**
 * A simple play button
 */
export const MediaButton = React.memo(
  ({ toggleMediaPlayer, setPlayingMedia, vzaarId, toolType, size = 32 }) => {
    const _onPlay = useCallback((e) => {
      e.preventDefault();
      setPlayingMedia({
        toolType,
        playerPath: vzaarId,
      });
      toggleMediaPlayer();
    });
    if (vzaarId) {
      return (
        <button aria-label="play" className={styles.button} onClick={_onPlay}>
          <IconPlay size={size} />
        </button>
      );
    }

    return null;
  }
);

MediaButton.propTypes = {
  /**
   * Media Player toggle, found in useMediaPlayer
   */
  toggleMediaPlayer: PropTypes.func.isRequired,

  /**
   * Sets playing media, found in useMediaPlayer
   */
  setPlayingMedia: PropTypes.func.isRequired,

  /**
   * Vzaar media player URL
   */
  vzaarId: PropTypes.string,

  /**
   * Media type, used in MediaModal to format for audio or video
   */
  toolType: PropTypes.number,

  /**
   * Size of the button in pixels square.
   */
  size: PropTypes.number,
};
