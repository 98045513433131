/**
 * Format string to title case. 
 */
export const toTitleCase = (str, delimiters, keepUppers) => {
  if (!str || str.length == 0) {
    return "";
  }

  if (!delimiters) {
    delimiters = [" "];
  }

  if(str.includes("WWG Guest")){
    return str
  }

  const _keepUppers = keepUppers || ["wwg"];
  if (_keepUppers && _keepUppers.indexOf(str.trim().toLowerCase()) > -1) {
    return str.toUpperCase();
  }

  let upper = true;
  let newStr = "";

  for (let i = 0; i < str.length; i++) {
    if (delimiters.indexOf(str[i]) > -1) {
      upper = true;
      newStr += str[i];
      continue;
    }

    newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase();
    upper = false;
  }

  return newStr;
}