import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./index.module.css";

/**
 * Container for thumbnail images.
 *
 * @example
 * <Thumbnail
 *   src={myImagePath}
 *   alt="Description of image"
 *   onClick={() => {
 *     // It's a button if you want it to be.
 *   }}
 * />
 */
export function Thumbnail({ src, alt, className }) {
  let image;

  if (src) {
    image = <img src={src} alt={alt || "thumbnail image"} />;
  } else {
    image = (
      <div className={styles.placeholder}>
        <span>No Image</span>
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.thumbnail, className, {
        [styles.empty]: src == null || src.trim() === "",
      })}
    >
      {image}
    </div>
  );
}

Thumbnail.propTypes = {
  /**
   * Path to an image to display.
   */
  src: PropTypes.string,

  /**
   * Alt attribute describing the image.
   */
  alt: PropTypes.string,

  /**
   * Additional class to add to thumbnail element.
   */
  className: PropTypes.string,
};

/**
 * Circular container for thumbnail images.
 *
 * @example
 * <CircleThumbnail
 *   src={myImagePath}
 *   alt="Description of image"
 *   onClick={() => {
 *     // It's a button if you want it to be.
 *   }}
 * />
 */
export function CircleThumbnail({ ...props }) {
  return <Thumbnail className={styles.circle} {...props} />;
}

CircleThumbnail.propTypes = Thumbnail.propTypes;
