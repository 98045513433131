import { useState } from "react";
import { useToggle } from "react-use";
import { createContainer } from "unstated-next";

export default function useMediaPlayer() {
  const [showMediaPlayer, toggleMediaPlayer] = useToggle(false);
  const [showSortModal, toggleSortModal] = useToggle(false);
  const [playingMedia, setPlayingMedia] = useState(null);
  const [showFilterModal, toggleFilterModal] = useToggle(false);

  return {
    toggleMediaPlayer,
    toggleSortModal,
    toggleFilterModal,
    setPlayingMedia,
    playingMedia,
    showSortModal,
    showFilterModal,
    showMediaPlayer,
  };
}

export const MediaPlayerContainer = createContainer(useMediaPlayer);
