import React from "react";
import classNames from "classnames";
import { useRouteMatch, useHistory } from "react-router-dom";
import MediaModal from "./components/MediaModal/MediaModal";
import LoadingPage from "shared/LoadingPage";
import styles from "./index.module.css";
import { FilterColumn } from "shared/FilterColumn";
import { StockListItem } from "./components/StockListItem/StockListItem";
import { SortDropdown } from "./components/SortDropdown/SortDropdown";
import SearchInput from "shared/@forms/SearchInput";
import { StockData } from "./hooks/useStockData";
import PropTypes from "prop-types";
import { Auth } from "shared/@auth";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

/**
 * List that is used to display, sort and filter any array of stock items
 */
export function PureStockList({
  isLoading,
  filters,
  selectedFilters,
  setSelectedFilters,
  sortBy,
  setSortBy,
  setSearchTerm,
  searchTerm,
  items,
  hasNextPage,
  fetchNextPage,
}) {
  const onSearch = (value) => {
    setSearchTerm(value);
  };

  if (isLoading && (!searchTerm || searchTerm.length == 0)) {
    return <LoadingPage />;
  }

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  console.log("hasNextPage", hasNextPage);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.row, styles.main)}>
        <div className={classNames(styles.col, styles.filters)}>
          <div className={classNames(styles.search)}>
            <SearchInput onSearch={onSearch} value={searchTerm} timeout={300} />
          </div>
          <FilterColumn
            filters={filters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </div>
        {isLoading && searchTerm && searchTerm.length > 0 ? (
          <div className={classNames(styles.col, styles.content)}>
            <LoadingPage />
          </div>
        ) : null}
        {!isLoading && (
          <div className={classNames(styles.col, styles.content)}>
            <SortDropdown sortBy={sortBy} setSortBy={setSortBy} />
            {items.length === 0 ? (
              <p style={{ textAlign: "center" }}>No Items Found</p>
            ) : (
              <InfiniteScroll
                dataLength={items.length}
                next={() => {
                  loadMore();
                }}
                hasMore={hasNextPage}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                {items.map((item, i) => (
                  <StockListItem
                    key={i}
                    imagePath={
                      item.images
                        ? item.images.length > 1
                          ? item.images[1]
                          : item.images[0]
                        : null
                    }
                    stockId={item.stockId}
                    isActive={item.isActive}
                    creator={item.creator}
                    description={item.description}
                    is3rdParty={item.is3rdParty}
                    sku={item.sku}
                    subtitle={item.subtitle}
                    title={item.title}
                    toolType={item.toolType}
                    vzaarId={item.vzaarId}
                  />
                ))}
              </InfiniteScroll>
            )}
          </div>
        )}
      </div>
      <MediaModal />
    </div>
  );
}

PureStockList.propTypes = {
  /**
   * Boolean value that determines if the API route is loading
   */
  isLoading: PropTypes.bool,

  /**
   * Array that holds all the possible Stock List filters from the database
   */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      array: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          desiredResult: PropTypes.bool,
        })
      ),
    })
  ),

  /**
   * Array that holds all the Stock List filters from the database that were selected by the user
   */
  selectedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      array: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          desiredResult: PropTypes.bool,
        })
      ),
    })
  ),

  /**
   * Function that updates the filters that the user has selected
   */
  setSelectedFilters: PropTypes.func,

  /**
   * String that holds which sorting option that the user has choosen
   */
  sortBy: PropTypes.string,

  /**
   * Function that updates the sorting option that the user has choosen
   */
  setSortBy: PropTypes.func,

  /**
   * Function that updates the search term that the user has entered
   */
  setSearchTerm: PropTypes.func,

  /**
   * String that holds the search term that the user has entered to filter the Stock List
   */
  searchTerm: PropTypes.string,

  /**
   * Array that holds the filtered Stock Items
   */
  filteredItems: PropTypes.arrayOf(
    PropTypes.shape({
      stockId: PropTypes.number,
      images: PropTypes.arrayOf(PropTypes.string),
      publishedOn: PropTypes.string,
      isActive: PropTypes.bool,
      creator: PropTypes.string,
      description: PropTypes.string,
      is3rdParty: PropTypes.bool,
      sku: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string,
      toolType: PropTypes.number,
      tags: PropTypes.arrayOf(PropTypes.string),
      vzaarId: PropTypes.string,
    })
  ),
};

/**
 * List that is used to display, sort and filter an array of stock items that are specific to the BSM
 */
export default function StockList() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const {
    isLoading,
    isError,
    filters,
    selectedFilters,
    setSelectedFilters,
    sortBy,
    setSortBy,
    setSearchTerm,
    searchTerm,
    archiveContent,
    items,
    hasNextPage,
    fetchNextPage,
  } = StockData.useContainer();
  const { hasPermission } = Auth.useContainer();

  return PureStockList({
    path,
    history,
    isLoading,
    isError,
    filters,
    selectedFilters,
    setSelectedFilters,
    sortBy,
    setSortBy,
    setSearchTerm,
    searchTerm,
    archiveContent,
    items,
    hasNextPage,
    fetchNextPage,
    hasPermission: hasPermission("marketing.cms.write"),
  });
}
