import styles from "./index.module.css";
import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Input from "shared/@forms/Input";

/**
 * A text input for search.
 *
 * @example
 * <SearchInput
 *   value={searchTerm} // override the search term from outside (optional)
 *   onSearch={newTerm => {
 *     // Do something with the new search term.
 *   }}
 *   timeout={300} // call onSearch 300ms after the last keystroke
 *   minLength={5} // require 5 characters minimum to run onSearch
 * />
 */
export default function SearchInput({
  className,
  value,
  onSearch,
  timeout = 0,
  minLength = null,
  ...props
}) {
  const ref = useRef(null);
  const [editValue, setEditValue] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  // Update editValue when value prop changes.
  useEffect(() => {
    if (typeof value === "string") {
      setEditValue(value);
    } else if (value == null) {
      setEditValue("");
    } else {
      throw new Error(
        `SearchInput value must be a string or undefined/null. Got: ${value} (${typeof value})`
      );
    }
  }, [value]);

  const _update = useCallback(
    (term) => {
      setEditValue(term);

      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }

      if (minLength && term.length < minLength) {
        return;
      }

      if (onSearch) {
        if (timeout > 0) {
          const id = setTimeout(() => {
            onSearch(term);
          }, timeout);

          setTimeoutId(id);
        } else {
          onSearch(term);
        }
      }
    },
    [timeoutId, onSearch]
  );

  const _clear = useCallback(() => {
    setEditValue("");

    if (onSearch) {
      onSearch("");
    }
  }, [onSearch]);

  return (
    <div className={styles.container}>
      <Input
        placeholder="Search"
        type="text"
        {...props}
        value={editValue}
        ref={ref}
        onChange={(e) => {
          _update(e.target.value);

          if (props.onChange) {
            props.onChange(e);
          }
        }}
        onKeyDown={(e) => {
          if (e.key.toLowerCase() === "escape") {
            _clear();
          }

          if (props.onKeyDown) {
            props.onKeyDown(e);
          }
        }}
        className={classNames(styles.input, className)}
      />

      <div className={styles.icon}>
        <span />
        <span />
      </div>

      {editValue.length > 0 && (
        <Fragment>
          {minLength && editValue.length < minLength && (
            <span className={styles.tooShortWarning}>
              {minLength - editValue.length} more character
              {minLength - editValue.length === 1 ? "" : "s"} required
            </span>
          )}

          <button
            type="button"
            className={styles.clearButton}
            onClick={() => {
              _clear();
            }}
          >
            <span />
            <span />
          </button>
        </Fragment>
      )}
    </div>
  );
}

SearchInput.propTypes = {
  /**
   * Callback that receives the new search term when it changes. Takes a single string.
   */
  onSearch: PropTypes.func,

  /**
   * Override the value. Replaces the current value whenever the passed value changes.
   */
  value: PropTypes.string,

  /**
   * Optional amount of milliseconds to wait before calling onChange.
   * Use if you want to wait until the user has stopped typing.
   */
  timeout: PropTypes.number,

  /**
   * Optional minimum characters to type before onSearch will be called.
   */
  minLength: PropTypes.number,
};
