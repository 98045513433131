import styles from "./index.module.css";
import PropTypes from "prop-types";

/**
 * This component is a conglomeration of text inputs and a textarea, their operating specs such as maxLength, and formatting.
 * @example
 *  <TextInputForm
 *    title={"title"}
 *    subtitle={"subtitle"}
 *    creator={"creator"}
 *    description={"description"}
 *    setTitle={setTitle}
 *    setCreator={setCreator}
 *    setSubtitle={setSubtitle}
 *    setDescription={setDescription}
 *  />
 */

export function TextInputForm({
  title = "",
  creator = "",
  subtitle = "",
  description = "",
  setTitle,
  setCreator,
  setSubtitle,
  setDescription,
}) {
  return (
    <section className={styles.container}>
      <label className={styles.label} htmlFor={"BSMStockTitle"}>
        Title
      </label>
      <input
        className={styles.input}
        id={"BSMStockTitle"}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        value={title}
        maxLength={250}
        required
      />
      <label
        className={styles.label}
        htmlFor={"BSMStockCreator/Speaker/Author"}
      >
        Creator/speaker/Author
      </label>
      <input
        className={styles.input}
        id={"BSMStockCreator/Speaker/Author"}
        onChange={(e) => {
          setCreator(e.target.value);
        }}
        value={creator}
        maxLength={500}
      />
      <label className={styles.label} htmlFor={"BSMStockSubtitle/Bookcategory"}>
        Occupation/BookCategory
      </label>
      <input
        className={styles.input}
        id={"BSMStockSubtitle/Bookcategory"}
        onChange={(e) => {
          setSubtitle(e.target.value);
        }}
        value={subtitle}
        maxLength={250}
      />
      <label className={styles.label} htmlFor={"BSMStockDescription"}>
        Description
      </label>
      <textarea
        maxLength={2000}
        id="BSMStockDescription"
        className={styles.textarea}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        value={description}
      />
    </section>
  );
}

TextInputForm.propTypes = {
  /**
   * @param title - the local value for a stock item's title
   */
  title: PropTypes.string,
  /**
   * @param creator - the local value for a stock item's creator
   */
  creator: PropTypes.string,
  /**
   * @param subtitle - the local value for a stock item's subtitle
   */
  subtitle: PropTypes.string,
  /**
   * @param description - the local value for a stock item's description
   */
  description: PropTypes.string,
  /**
   * @param setTitle - a function for setting the local state's title value
   */
  setTitle: PropTypes.func,
  /**
   * @param setCreator - a function for setting the local state's creator value
   */
  setCreator: PropTypes.func,
  /**
   * @param setSubtitle - a function for setting the local state's subtitle value
   */
  setSubtitle: PropTypes.func,
  /**
   * @param setDescription - a function for setting the local state's description value
   */
  setDescription: PropTypes.func,
};
