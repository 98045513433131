import styles from "./index.module.css";
import PropTypes from "prop-types";
import DeleteItem from "../DeleteItem/index.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import GreenCheckmark from "../GreenCheckmark";
import WaitingGear from "../WaitingGear";
import { Thumbnail } from "../../../../shared/Thumbnail";

/**
 * Displays a preview for the new stock items image along with the old image and the stock items info.
 */
export default function ListItem({
  newImage,
  sku,
  uploaded,
  error,
  stockItems,
  isWaiting,
  listItems,
  setListItems,
  setErrMessage,
}) {
  let url = "";
  if (typeof newImage === "string") {
    url = newImage;
  } else {
    url = URL.createObjectURL(newImage);
  }

  const onDelete = () => {
    setErrMessage("");
    setListItems(listItems.filter((item) => item.sku != sku));
  };

  const stockItem = stockItems.find((item) => item.sku === sku);

  return (
    <div>
      <div className={styles.container}>
        {isWaiting ? (
          <WaitingGear />
        ) : !error ? (
          stockItem !== undefined && uploaded ? (
            <GreenCheckmark />
          ) : (
            <DeleteItem onDelete={onDelete} />
          )
        ) : (
          <FontAwesomeIcon icon={faBan} className={styles.error} />
        )}
        {stockItem !== undefined ? (
          stockItem.images ? (
            <>
              {stockItem.images[0] ? (
                <div>
                  <img
                    className={styles.imgOne}
                    src={stockItem.images[0]}
                    alt="Image One"
                  />
                </div>
              ) : (
                <div>
                  <Thumbnail
                    className={styles.blankImgTwo}
                    src=""
                    alt="No Thumbnail"
                  />
                </div>
              )}
              {stockItem.images[1] ? (
                <div>
                  <img
                    className={styles.imgTwo}
                    src={stockItem.images[1]}
                    alt="Image Two"
                  />
                </div>
              ) : (
                <div>
                  <Thumbnail
                    className={styles.blankImgTwo}
                    src=""
                    alt="No Thumbnail"
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div>
                <Thumbnail
                  className={styles.blankImgOne}
                  src=""
                  alt="No Thumbnail"
                />
              </div>
              <div>
                <Thumbnail
                  className={styles.blankImgTwo}
                  src=""
                  alt="No Thumbnail"
                />
              </div>
            </>
          )
        ) : null}
        <div className={styles.arrowBorder}>
          <FontAwesomeIcon
            icon={faArrowAltCircleRight}
            className={styles.arrow}
            size="2x"
          />
        </div>
        <div>
          <img className={styles.imgOne} src={url} alt="New Image" />
        </div>
        <div>
          <img className={styles.imgTwo} src={url} alt="New Image" />
        </div>
        {stockItem !== undefined ? (
          <>
            <div className={styles.about}>
              <div className={styles.aboutTitle}>
                <div>{stockItem.title}</div>
              </div>
              <div className={styles.aboutInfo}>
                <div>Creator: {stockItem.creator}</div>
                <div>SKU: {stockItem.sku}</div>
                <div>ID: {stockItem.stockId}</div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.notFound}>SKU NOT FOUND</div>
        )}
      </div>
    </div>
  );
}

ListItem.propTypes = {
  /**
   * value passed in to display a preview for the new stock items image.
   */
  newImage: PropTypes.object,

  /**
   * SKU passed in to find the stock item.
   */
  sku: PropTypes.string,

  /**
   * Bool to show if the new image has been uploaded yet. Once it's uploaded the green checkmark will be displayed.
   */
  uploaded: PropTypes.bool,

  /**
   * Bool to show if there is a problem with the new image.
   */
  error: PropTypes.bool,

  /**
   * Array of stock items.
   */
  stockItems: PropTypes.arrayOf(
    PropTypes.shape({
      stockId: PropTypes.number,
      images: PropTypes.arrayOf(PropTypes.string),
      publishedOn: PropTypes.string,
      isActive: PropTypes.bool,
      creator: PropTypes.string,
      description: PropTypes.string,
      is3rdParty: PropTypes.bool,
      sku: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string,
      toolType: PropTypes.number,
      tags: PropTypes.arrayOf(PropTypes.string),
      vzaarId: PropTypes.string,
    })
  ),

  /**
   * Bool to hold if API is currently loading.
   */
  isWaiting: PropTypes.bool,

  /**
   * Array of new images to upload.
   */
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      uploaded: PropTypes.bool,
      error: PropTypes.bool,
      file: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(
          PropTypes.shape({
            lastModified: PropTypes.number,
            lastModifiedDate: PropTypes.string,
            name: PropTypes.string,
            size: PropTypes.number,
            type: PropTypes.string,
            webkitRelativePath: PropTypes.string,
          })
        ),
      ]),
    })
  ),

  /**
   * Function that updates the list of new images.
   */
  setListItems: PropTypes.func,

  /**
   * Function to update error message.
   */
  setErrMessage: PropTypes.func,
};
