import { useState } from "react";
import { useEffect, useMemo } from "react";
import { Auth } from "shared/@auth/Auth";
import { isEqual, uniq } from "lodash-es";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useMethods } from "react-use";
import { StockData } from "BSM/StockList/hooks/useStockData";

export function useStockEditData(id) {
  const queryClient = useQueryClient();
  const { makeAuthedRequest } = Auth.useContainer();
  const stockId = id;

  function createMethods(state) {
    return {
      set(newState) {
        return newState;
      },

      reset() {
        return initialState;
      },

      setCreator(creator) {
        return { ...state, creator };
      },

      setTitle(title) {
        return { ...state, title };
      },

      setSubtitle(subtitle) {
        return { ...state, subtitle };
      },

      setDescription(description) {
        return { ...state, description };
      },

      setTags(tags) {
        return { ...state, tags };
      },

      // this method handles the locally added tags before they are attached to the stock item via request
      setNewTags(newTags) {
        return { ...state, newTags };
      },

      // this reducer handles the blob received from the api and only deals with blobs it treats `image' as `images` for the sake of comparison against the api storage name
      setImages(image) {
        return { ...state, images: image };
      },

      setImageFile(imageFile) {
        let image = URL.createObjectURL(imageFile);
        return { ...state, imageFile, images: image };
      },
    };
  }

  const stockQuery = useQuery(
    ["bsm", id],
    async () =>
      await makeAuthedRequest(`/bsm/stock/${id}`).then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          return json;
        } else {
          throw new Error(json.message);
        }
      })
  );

  // the utilization of a singular image in the state reflects the ability to manipulate only the thumbnail image from this hook and its associated route
  const initialState = useMemo(() => {
    if (stockQuery.isSuccess) {
      let thumbnail = null;
      if (stockQuery.data.images && stockQuery.data.images.length > 0) {
        thumbnail = stockQuery.data.images.find((image) => {
          return image.includes("thumb");
        });
        return { ...stockQuery.data, images: thumbnail ?? null };
      }
      return { ...stockQuery.data, images: thumbnail };
    }
    return {
      creator: "",
      description: "",
      title: "",
      subtitle: "",
      tags: [],
      images: null,
    };
  }, [stockQuery.data, stockQuery.isSuccess]);

  const [content, methods] = useMethods(createMethods, { ...initialState });

  // Reset form data and original reference when request data changes.
  useEffect(() => {
    if (stockQuery.data != null) {
      let thumbnail = null;
      if (stockQuery.data.images && stockQuery.data.images.length > 0) {
        thumbnail = stockQuery.data.images.find((image) => {
          return image.includes("thumb");
        });
      }
      methods.set({ ...stockQuery.data, images: thumbnail ?? null });
    }
  }, [stockQuery.data]);

  const isChanged = useMemo(() => {
    let result = !isEqual(
      { ...initialState, tags: null },
      { ...content, tags: null }
    );

    if (result) {
      return result;
    }

    if (initialState.tags.length != content.tags.length) {
      return true;
    }

    let tagIndexes = initialState.tags.length;
    for (let i = 0; i < tagIndexes; i++) {
      let tagsAreChanged = !initialState.tags.includes(content.tags[i]);
      if (tagsAreChanged) {
        return true;
      }
      result = false;
    }
    return result;
  }, [initialState, content]);

  const [imageData, setImageData] = useState();

  // this takes the blob and makes a sizable image for use in updateStockImageMutation
  useEffect(() => {
    let image = new Image();
    image.src = content.images;

    image.onload = function () {
      setImageData({
        height: this.naturalHeight,
        width: this.naturalWidth,
      });
    };
  }, [content.images]);

  const updateStockImageThumbMutation = useMutation(
    async ({imageFile}) => {
      let url = `bsm/stock/${stockId}/image`;
      const data = new FormData();

      if (stockQuery.isSuccess && (content.imageFile || imageFile) && imageData) {
        const imageFileToUpload = new File([content.imageFile || imageFile], "thumb.png");

        const { height, width } = imageData;
        data.append("file", imageFileToUpload);
        url = `bsm/stock/${stockId}/image?height=${height}&width=${width}&type=2`;

        return await makeAuthedRequest(url, {
          headers: {
            "Content-Type": undefined,
          },
          method: "PUT",
          body: data,
        }).then(async (res) => {
          if (res.ok) {
            const json = await res.json();
          } else {
            console.warn(res);
            throw new Error(res.message);
          }
        });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bsm", "all"]);
        queryClient.invalidateQueries(["bsm", stockId]);
      },
    }
  );

  const updateStockImageFullMutation = useMutation(
    async ({imageFile}) => {
      let url = `bsm/stock/${stockId}/image`;
      const data = new FormData();

      if (stockQuery.isSuccess && (content.imageFile || imageFile) && imageData) {
        const imageFileToUpload = new File([content.imageFile || imageFile], "full.png");

        const { height, width } = imageData;
        data.append("file", imageFileToUpload);
        url = `bsm/stock/${stockId}/image?height=${height}&width=${width}&type=1`;

        return await makeAuthedRequest(url, {
          headers: {
            "Content-Type": undefined,
          },
          method: "PUT",
          body: data,
        }).then(async (res) => {
          if (res.ok) {
            const json = await res.json();
          } else {
            console.warn(res);
            throw new Error(res.message);
          }
        });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bsm", "all"]);
        queryClient.invalidateQueries(["bsm", stockId]);
      },
    }
  );

  const updateStockMutation = useMutation(
    async () =>
      makeAuthedRequest(`bsm/stock/${stockId}`, {
        method: "PUT",
        body: JSON.stringify({
          creator: content.creator ? content.creator : null,
          description: content.description ? content.description : null,
          title: content.title ? content.title : null,
          subtitle: content.subtitle ? content.subtitle : null,
          tags: content.tags ? (content.tags.length ? content.tags : []) : [],
        }),
      }).then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          return json;
        } else {
          throw new Error(json.message);
        }
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bsm", "all"]);
        queryClient.invalidateQueries(["bsm", stockId]);
        queryClient.invalidateQueries(["bsm", "tags"]); //Do this to show any new tags
      },
    }
  );

  return {
    data: stockQuery.data,
    isLoading: stockQuery.isLoading,
    isError: stockQuery.isError,
    isSuccess: stockQuery.isSuccess,
    error: stockQuery.error,
    isChanged,
    content,
    methods,
    updateStockMutation,
    updateStockImageThumbMutation,
    updateStockImageFullMutation
  };
}
