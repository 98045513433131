import PropTypes from "prop-types";
import styles from "./index.module.css";
import classNames from "classnames";
import { Close, IkonateContext } from "react-ikonate";

/**
 * A Checkbox label grouping. All state and state handling is passed in.
 *
 * @example
 *  <CategoryCheckbox
 *   className={styles.checkbox}
 *   isChecked={isCheckedHandler}
 *   onClick={() => toggleChecked()}
 *   label={"Label"}
 *  />
 */

export function CategoryCheckbox({
  label = "",
  isChecked = false,
  onClick,
  className,
}) {
  return (
    <label
      className={classNames(
        styles.pairContainer,
        isChecked ? styles.containerChecked : null,
        className
      )}
    >
      <div className={styles.container}>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={isChecked}
          onChange={onClick}
        />
        <div className={styles.icon}>
          <IkonateContext.Provider value={{ color: "#91b6bb", size: 25 }}>
            <Close />
          </IkonateContext.Provider>
        </div>
      </div>
      <div className={styles.label}>{label}</div>
    </label>
  );
}

CategoryCheckbox.propTypes = {
  /**
   * @param label - a string to be used as a label
   */
  label: PropTypes.string,
  /**
   * @param isChecked - a boolean state of the checkbox
   */
  isChecked: PropTypes.bool,
  /**
   * @param onClick - a function to determine the state of the checkbox
   */
  onClick: PropTypes.func,
  /**
   * @param className - styles to modify the original design
   */
  className: PropTypes.string,
};
