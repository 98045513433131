import PropTypes from "prop-types";
import { useState } from "react";
import styles from "./index.module.css";
import { Thumbnail } from "../../../../shared/Thumbnail";
import { Button } from "shared/Button/Button";
import ChangeImageModal from "../ChangeImageModal";

/**
 * This component is a thumbnail display and button for controlling ChangeImageModal
 * it utilizes externally provided images and functions for displaying and storing image files
 *
 * @example
 *  <ChangeImage
 *    stockImage={content ? content.images : ""}
 *    onChangeImage={setImageFiles}
 *  />
 */

export function ChangeImage({ stockImage, onChangeImage }) {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <Thumbnail
          className={styles.thumbnail}
          src={stockImage ?? ""}
          alt={stockImage ?? "Missing Image"}
        />
        <Thumbnail
          className={styles.thumbnail}
          src={stockImage ?? ""}
          alt={stockImage ?? "Missing Image"}
        />
        <Button onClick={() => setModalOpen(true)}>Change Image</Button>
        <ChangeImageModal
          isOpen={isModalOpen}
          setModalOpen={setModalOpen}
          onChooseImage={(img) => {
            onChangeImage(img);
          }}
          className={{
            buttonSection: styles.buttonSection,
          }}
        />
      </div>
    </>
  );
}

ChangeImage.propTypes = {
  /**
   * @param stockImage - the path to the currently observed stock's thumbnail image
   */
  stockImage: PropTypes.string,
  /**
   * @param onChangeImage - function to control the storing and modification of an image file
   */
  onChangeImage: PropTypes.func,
};
