import React, { useEffect } from "react";
import styles from "./index.module.css";
import Header from "./components/Header";
import SelectPhotos from "./components/SelectPhotos";
import UploadImages from "./components/UploadImages";
import { ButtonPurpose } from "shared/Button/Button";
import ListItem from "./components/ListItem";
import { ImageUploader } from "./useImageUploader";
import { Button } from "shared/Button";
import { StockData } from "BSM/StockList/hooks/useStockData";
import LoadingPage from "shared/LoadingPage";

/**
 * The Main Page for the Image Uploader
 */
export default function ImageUpload() {
  const {
    stockItems,
    isWaiting,
    listItems,
    setListItems,
    errMessage,
    setErrMessage,
    onUpload,
  } = ImageUploader.useContainer();
  const { refreshAll, isLoading, items } = StockData.useContainer();

  useEffect(() => {
    if (!items) {
      refreshAll();
    }
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Header />
      <div className={styles.btnGroup}>
        <SelectPhotos
          setListItems={setListItems}
          setErrMessage={setErrMessage}
        />
        <UploadImages onUpload={onUpload} />
        {listItems.length > 0 ? (
          <Button
            purpose={ButtonPurpose.delete}
            onClick={() => {
              setListItems([]);
              setErrMessage("");
            }}
          >
            Clear List
          </Button>
        ) : null}
      </div>
      <div className={styles.errMessage}>{errMessage}</div>
      {listItems.map((item) => {
        return (
          <ListItem
            newImage={item.file}
            sku={item.sku}
            uploaded={item.uploaded}
            error={item.error}
            stockItems={stockItems}
            isWaiting={isWaiting}
            listItems={listItems}
            setListItems={setListItems}
            setErrMessage={setErrMessage}
          />
        );
      })}
    </>
  );
}
