import React, { useState, useCallback, useEffect } from "react";
import ImagePicker from "../ImagePicker";
import PropTypes from "prop-types";

/**
 * Button used to select an image and add it to the list items. Once selected a new list items is added with the file and sku set to the files name. If the user doesn't follow correct sizing conventions an error is displayed instead of adding the list item.
 */
export default function SelectPhotos({ setListItems, setErrMessage }) {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [skus, setSkus] = useState([]);

  const selectImageFile = useCallback(
    (files) => {
      if (files) {
        Object.values(files).forEach((image) => {
          if (image.name.includes(".png")) {
            setSkus((skus) => [...skus, image.name.replace(".png", "")]);
          } else if (image.name.includes(".jpeg")) {
            setSkus((skus) => [...skus, image.name.replace(".jpeg", "")]);
          } else if (image.name.includes(".jpg")) {
            setSkus((skus) => [...skus, image.name.replace(".jpg", "")]);
          }
          setselectedFiles((selectedFiles) => [...selectedFiles, image]);
        });
      }
    },
    [selectedFiles]
  );

  useEffect(() => {
    if (selectedFiles) {
      selectedFiles.forEach((image) => {
        setErrMessage("");
        const url = URL.createObjectURL(image);
        const img = new Image();
        img.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 600 || width < 600 || height !== width) {
            setErrMessage(
              "Images must be square with minimum dimensions of 600 x 600px"
            );
            return false;
          } else {
            let imageExtension = "";

            if (image.name.includes(".png")) {
              imageExtension = ".png";
            } else if (image.name.includes(".jpeg")) {
              imageExtension = ".jpeg";
            } else if (image.name.includes(".jpg")) {
              imageExtension = ".jpg";
            }

            let listItem = {
              sku: skus.find(
                (sku) => sku === image.name.replace(imageExtension, "")
              ),
              uploaded: false,
              error: false,
              file: image,
              width: width,
              height: height,
            };
            setListItems((listItems) => [...listItems, listItem]);
            setselectedFiles([]);
            setSkus([]);
          }
        };
        img.src = url;

        return () => {
          URL.revokeObjectURL(url);
        };
      });
    }
  }, [selectedFiles]);

  return (
    <div>
      <ImagePicker
        label="Select Photos"
        value={selectedFiles}
        onChange={(files) => selectImageFile(files)}
      />
    </div>
  );
}

SelectPhotos.propTypes = {
  /** Function that updates list items */
  setListItems: PropTypes.func,

  /** Function that updates error message */
  setErrMessage: PropTypes.func,
};
