import MainNav from "@wwg/support-ui/dist/Components/MainNav/MainNav";
import { ENVIRONMENT_CONFIG } from "shared/globals";
import "react-notifications-component/dist/theme.css";
import styles from "./App.module.css";

import React, { Suspense, useState } from "react";
import { ReactNotifications } from "react-notifications-component";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useTitle } from "react-use";

import { NavState } from "@wwg/support-ui/dist/Components/Nav/useNavState";
import { Auth } from "shared/@auth/Auth";
import { CurrentRouteContext } from "./shared/useCurrentRoute";
import SectionErrorBoundary from "shared/SectionErrorBoundary";
import LoadingPage from "./shared/LoadingPage";
import BSM from "./BSM";

const { pageTitle, externalLinks, flags } = ENVIRONMENT_CONFIG;

function App() {
  useTitle(pageTitle);
  const { hasPermission } = Auth.useContainer();
  const { collapsed: navCollapsed } = NavState.useContainer();
  const [currentRoute, setCurrentRoute] = useState();

  return (
    <Router>
      <ReactNotifications />
      <CurrentRouteContext.Provider value={{ currentRoute, setCurrentRoute }}>
        <div className={styles.layout}>
          <MainNav
            fetchManagementTeamNotification={
              flags.fetchManagementTeamNotification &&
              hasPermission("management-team.*")
            }
            auth={Auth}
            navCollapsed={navCollapsed}
            externalLinks={externalLinks}
            currentRoute={currentRoute}
          />

          <main className={styles.main}>
            <SectionErrorBoundary>
              <Suspense fallback={<LoadingPage />}>
                <Switch>
                  {hasPermission("bsm.*") && (
                    <Route path="/bsm">
                      <BSM />
                    </Route>
                  )}

                  <Route path="*">
                    {hasPermission("bsm.*") ? (
                      <Redirect to="/bsm" />
                    ) : (
                      <NotFound />
                    )}
                  </Route>
                </Switch>
              </Suspense>
            </SectionErrorBoundary>
          </main>
        </div>
      </CurrentRouteContext.Provider>
    </Router>
  );
}
export default App;

function NotFound() {
  return (
    <div className={styles.notFound}>
      <h1>Welcome to WWG Support</h1>
      <p>Choose a section on the left sidebar to get started.</p>
    </div>
  );
}
