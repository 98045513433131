import React from "react";
import { Cancel, IkonateContext } from "react-ikonate";
import PropTypes from "prop-types";
import styles from "./index.module.css";

/**
 * Displays a delete button with a passed in delete function delete function
 */
export default function DeleteItem({ onDelete }) {
  return (
    <div className={styles.deleteItem} onClick={onDelete}>
      <IkonateContext.Provider value={{ size: 22, border: 1 }}>
        <Cancel />
      </IkonateContext.Provider>
    </div>
  );
}

DeleteItem.propTypes = {
  /**
   * Function that deletes the Item from the Image Uploader List
   */
  onDelete: PropTypes.func,
};
