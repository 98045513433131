import PropTypes from "prop-types";
import { useMemo } from "react";
import ToggleAddInput from "../ToggleAddInput";
import CategoryCheckbox from "../CategoryCheckbox";
import styles from "./index.module.css";

/**
 * A checkbox form for adding / retracting tags from a stock item for filtering later.
 * 
 * @example
 *  <CategoryCheckboxForm
 *     stockItemsTags={stockItemsTagsArray}
 *     setStockItemsTags={setTags()}
 *     setNewTags={setNewTags()}
 *     newTags={newTagsArray}
 *     tags={tagsArray}
 *   />
 */

export function CategoryCheckboxForm({
  stockItemsTags = [],
  setStockItemsTags,
  newTags = [],
  setNewTags,
  originalTags = [],
}) {
  function containsTags(tag) {
    return stockItemsTags.includes(tag);
  }

  function toggleChecked(tag) {
    const tagIsPresent = stockItemsTags.includes(tag);
    const foundIndex = stockItemsTags.findIndex(
      (observedTag) => observedTag === tag
    );
    if (tagIsPresent) {
      stockItemsTags.splice(foundIndex, 1);
    } else {
      stockItemsTags.push(tag);
    }
    setStockItemsTags(stockItemsTags);
  }

  function newTagHandler(tag) {
    let tagAlreadyAvailable =
      originalTags.includes(tag) || newTags.includes(tag);
    if (!tagAlreadyAvailable && tag) {
      setNewTags([...newTags, tag]);
      setStockItemsTags([...stockItemsTags, tag]);
    }
  }

  let tagOptionCheckboxes = useMemo(() => {
    return originalTags.map((tag) => {
      return (
        <CategoryCheckbox
          key={tag}
          className={styles.checkbox}
          id={tag}
          isChecked={containsTags(tag)}
          onClick={() => toggleChecked(tag)}
          label={tag}
        />
      );
    });
  }, [originalTags, stockItemsTags]);

  let newTagsOptionsCheckboxes = useMemo(() => {
    return newTags.map((tag) => {
      return (
        <CategoryCheckbox
          key={tag}
          className={styles.checkbox}
          id={tag}
          isChecked={containsTags(tag)}
          onClick={() => toggleChecked(tag)}
          label={tag}
        />
      );
    });
  }, [newTags, stockItemsTags]);

  return (
    <section className={styles.container}>
      <header className={styles.header}>Primary Categories</header>
      <article className={styles.checkboxContainer}>
        {tagOptionCheckboxes}
        {newTagsOptionsCheckboxes}
        <ToggleAddInput
          className={styles.checkbox}
          onAdd={(text) => {
            newTagHandler(text);
          }}
        />
      </article>
    </section>
  );
}

CategoryCheckboxForm.propTypes = {
  /**
   * @param stockItemsTags - This is an array of tags (strings) the currently observed stock item has
   */
  stockItemsTags: PropTypes.arrayOf(PropTypes.string),
  /**
   * @param setStockItemsTags - This function handles the current stock item's tag selection
   */
  setStockItemsTags: PropTypes.func,
  /**
   * @param setNewTags - This function handles the addition of a new tag into the newTags array
   */
  setNewTags: PropTypes.func,
  /**
   * @param newTags - This is an array of tags that have been added by the user
   */
  newTags: PropTypes.arrayOf(PropTypes.string),
  /**
   * @param tags - This is the original array of all unique tags across all stock items
   */
  originalTags: PropTypes.arrayOf(PropTypes.string),
};
