import React from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import { Thumbnail } from "../../../../shared/Thumbnail";
import { MediaButton } from "../MediaButton/MediaButton";
import { EditButton } from "../EditButton/EditButton";
import { MediaPlayerContainer } from "../../hooks/useMediaPlayer";
import PropTypes from "prop-types";

/**
 * Stock Item that shows all the information about the item
 */
export function PureStockListItem({
  imagePath,
  stockId,
  isActive,
  creator,
  sku,
  subtitle,
  title,
  toolType,
  vzaarId,
  toggleMediaPlayer,
  setPlayingMedia,
}) {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.col}>
          <div className={classNames(styles.row, styles.pad)}>
            <div className={classNames(styles.center, styles.mediaImg)}>
              <Thumbnail src={imagePath} alt={`${title}, thumbnail`} />
            </div>
            <div className={styles.details}>
              <div className={classNames(styles.col, styles.center)}>
                <div className={styles.title}>{title}</div>
                <div className={styles.contentAuthor}>{creator}</div>
                <div className={styles.smallText}>{sku}</div>
                <div className={styles.smallText}>{subtitle}</div>
                {!isActive && (
                  <div
                    className={classNames(styles.unavailable, styles.smallText)}
                  >
                    Unavailable
                  </div>
                )}
              </div>

              <div>
                <div className={classNames(styles.row, styles.button)}>
                  <MediaButton
                    toggleMediaPlayer={toggleMediaPlayer}
                    setPlayingMedia={setPlayingMedia}
                    toolType={toolType}
                    vzaarId={vzaarId}
                    size={32}
                  />
                  <EditButton StockItemId={stockId} size={32} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

PureStockListItem.propTypes = {
  /** Stock Item's Image */
  imagePath: PropTypes.string,

  /** Stock Id */
  stockId: PropTypes.number,

  /** Bool that holds if Stock Item is available */
  isActive: PropTypes.bool,

  /** Stock Item Creator */
  creator: PropTypes.string,

  /** SKU */
  sku: PropTypes.string,

  /** Stock Item Subtitle */
  subtitle: PropTypes.string,

  /** Stock Item Title */
  title: PropTypes.string,

  /** Stock Item's Tool Type */
  toolType: PropTypes.number,

  /** Stock Item's Vzaar Id */
  vzaarId: PropTypes.string,

  /** Function that toggles the media player */
  toggleMediaPlayer: PropTypes.func,

  /** Function that updates the current playing media */
  setPlayingMedia: PropTypes.func,
};

/**
 * Stock Item that is specific to the BSM
 */
export function StockListItem({
  imagePath,
  stockId,
  isActive,
  creator,
  sku,
  subtitle,
  title,
  toolType,
  vzaarId,
}) {
  const {
    toggleMediaPlayer,
    setPlayingMedia,
  } = MediaPlayerContainer.useContainer();

  return PureStockListItem({
    imagePath,
    stockId,
    isActive,
    creator,
    sku,
    subtitle,
    title,
    toolType,
    vzaarId,
    toggleMediaPlayer,
    setPlayingMedia,
  });
}

StockListItem.propTypes = {
  /** Stock Item's Image */
  imagePath: PropTypes.string,

  /** Stock Id */
  stockId: PropTypes.number,

  /** Bool that holds if Stock Item is available */
  isActive: PropTypes.bool,

  /** Stock Item Creator */
  creator: PropTypes.string,

  /** SKU */
  sku: PropTypes.string,

  /** Stock Item Subtitle */
  subtitle: PropTypes.string,

  /** Stock Item Title */
  title: PropTypes.string,

  /** Stock Item's Tool Type */
  toolType: PropTypes.number,

  /** Stock Item's Vzaar Id */
  vzaarId: PropTypes.string,
};
