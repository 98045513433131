import PropTypes from "prop-types";
import { useState } from "react";
import classNames from "classnames";
import styles from "./index.module.css";
import { Close } from "react-ikonate";
import { Button, ButtonSize } from "shared/Button";
import TextInput from "shared/@forms/TextInput";

/**
 * This component is a toggle-displayed input used to offer an input and a button execution of a function with the string as an argument.
 * This component's parameters allow for external controls and design for possible future use in lieu of refactoring.
 *
 * @example
 *    <ToggleAddInput
 *      isOpen={isDrawerOpen}
 *      inputValue={"This is the default value."}
 *      onAdd={(text) => {
 *        onAddHandler(text);
 *      }}
 *      className={styles.toggleInput}
 *    />
 */

export function ToggleAddInput({
  isOpen = false,
  inputValue = "",
  onAdd,
  className,
}) {
  const [inputDrawerIsOpen, setInputDrawerIsOpen] = useState(isOpen);
  const [input, setInput] = useState(inputValue);

  return (
    <>
      <label className={classNames(styles.pairContainer, className)}>
        <div className={styles.container}>
          <input
            type="checkbox"
            className={styles.checkbox}
            checked={inputDrawerIsOpen}
            onChange={() => setInputDrawerIsOpen(!inputDrawerIsOpen)}
          />
          <div className={styles.icon}>
            <Close />
          </div>
        </div>
        <div className={styles.label}>
          {!inputDrawerIsOpen ? <span>ADD</span> : null}
          {inputDrawerIsOpen && (
            <span className={styles.inputButtonDrawer}>
              <TextInput
                className={styles.input}
                value={input}
                onInput={(e) => setInput(e.target.value)}
                minLength={1}
                maxLength={25}
              />
              <Button
                size={ButtonSize.normal}
                onClick={() => {
                  onAdd(input);
                  setInput("");
                }}
              >
                ADD
              </Button>
            </span>
          )}
        </div>
      </label>
    </>
  );
}

ToggleAddInput.propTypes = {
  /**
   * @param isOpen - the state of whether or not the input is displayed
   */
  isOpen: PropTypes.bool,
  /**
   * @param inputValue - the string in the input field
   */
  inputValue: PropTypes.string,
  /**
   * @param onAdd - the function to execute when pressing the ADD button after opening the input with the checkbox
   */
  onAdd: PropTypes.func,
  /**
   * @param className - a way to alter the design of the component
   */
  className: PropTypes.string,
};
